import React, { useState, useRef } from 'react';
import './MP3Player.css';
import song from "../audio/blackSands.mp3";

const MP3Player = () => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [progress, setProgress] = useState(0);
  const audioRef = useRef(null);
  const progressBarRef = useRef(null); // Reference to the progress bar

  // Play/Pause button handler
  const handlePlayPause = () => {
    if (isPlaying) {
      audioRef.current.pause();
    } else {
      audioRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  // Update the progress based on the audio current time
  const handleTimeUpdate = () => {
    const currentTime = audioRef.current.currentTime;
    const duration = audioRef.current.duration;
    setProgress((currentTime / duration) * 100);
  };

  // Calculate the new progress based on user clicking or dragging the progress dot
  const updateProgress = (event) => {
    const progressBar = progressBarRef.current;
    const rect = progressBar.getBoundingClientRect();
    const offsetX = event.clientX || event.touches[0].clientX; // Use touch for mobile
    const newProgress = (offsetX - rect.left) / progressBar.offsetWidth * 100;
    setProgress(Math.min(Math.max(newProgress, 0), 100));
    audioRef.current.currentTime = (newProgress / 100) * audioRef.current.duration;
  };

  // Handle the start of dragging (mouse or touch)
  const handleDragStart = (event) => {
    event.preventDefault(); // Prevent text selection
    document.addEventListener('mousemove', handleDrag);
    document.addEventListener('mouseup', handleDragEnd);
    document.addEventListener('touchmove', handleDrag, { passive: false });
    document.addEventListener('touchend', handleDragEnd);
  };

  // Handle dragging progress (mouse or touch)
  const handleDrag = (event) => {
    updateProgress(event);
  };

  // Handle drag end
  const handleDragEnd = () => {
    document.removeEventListener('mousemove', handleDrag);
    document.removeEventListener('mouseup', handleDragEnd);
    document.removeEventListener('touchmove', handleDrag);
    document.removeEventListener('touchend', handleDragEnd);
  };

  // Handle click on progress bar (for desktop users)
  const handleProgressBarClick = (event) => {
    updateProgress(event);
  };

  return (
    <div>
        <div className="song-title">
        Black Sands - Bonobo
        </div>
        
 <div className="mp3-player">
        
        <div className="mp3-circle" onClick={handlePlayPause}>
          <div className="play-pause-btn">{isPlaying ? 'Pause' : 'Play'}</div>
        </div>
        <div className="progress-container" ref={progressBarRef} onClick={handleProgressBarClick}>
          <div className="progress-line">
            <div
              className="progress-dot"
              style={{ left: `${progress}%` }}
              onMouseDown={handleDragStart} // Mouse drag support
              onTouchStart={handleDragStart} // Touch drag support
            ></div>
          </div>
        </div>
        <audio
          ref={audioRef}
          src={song}
          onTimeUpdate={handleTimeUpdate}
          onEnded={() => setIsPlaying(false)} // Handle audio end
        />
      </div>
    </div>
   
  );
};

export default MP3Player;
