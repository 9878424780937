import React, { useState, useEffect } from 'react';
import { Button, Container, Row, Col, Modal, Form, Alert } from 'react-bootstrap';
import './App.css'; // Import the custom CSS
import MP3Player from "./components/MP3Player";

function App() {
  
  const [showModal, setShowModal] = useState(false);
  const [showLightsModal, setShowLightsModal] = useState(false);
  const [url, setUrl] = useState('');
  const [destinationEmail, setDestinationEmail] = useState('');
  const [responseMessage, setResponseMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [lightsState, setLightsState] = useState([
    { id: 1, color: 'black' },
    { id: 2, color: 'black' }
  ]); // Track each light's state individually

  const baseUrl = 'http://10.225.95.1:7485/api';

  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);
  const handleLightsShow = () => setShowLightsModal(true);
  const handleLightsClose = () => setShowLightsModal(false);

  const handleUrlChange = (event) => setUrl(event.target.value);
  const handleEmailChange = (event) => setDestinationEmail(event.target.value);

  const handleSubmit = async () => {
    setLoading(true);
    setResponseMessage('');
    try {
      const response = await fetch('http://10.225.95.1:8890/download-video', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ youtubeUrl: url, destinationEmail: destinationEmail }),
      });

      const data = await response.json();
      setResponseMessage(response.ok ? 'Email sent successfully!' : data.error || 'Something went wrong.');
    } catch (error) {
      setResponseMessage('Error communicating with server. Please try again.');
    } finally {
      setLoading(false);
      handleClose();
    }
  };

  const fetchLightState = async (lightId) => {
    try {
        const response = await fetch(`${baseUrl}/lights/${lightId}`);
        const data = await response.json();
        const isOn = data.state.on;
        const brightness = data.state.bri;

        if (!isOn) {
            return 'black';
        }

        if (brightness <= 51) {
            return 'orange';
        }

        return 'green';
    } catch (error) {
        console.error(`Error fetching state for Light ${lightId}:`, error);
        return 'black'; // Default to black on error
    }
};

  useEffect(() => {
    const fetchLightsStates = async () => {
      const light1Color = await fetchLightState(1);
      const light2Color = await fetchLightState(2);
      setLightsState([
        { id: 1, color: light1Color },
        { id: 2, color: light2Color },
      ]);
    };

    fetchLightsStates();
  }, []);

  const toggleLightColor = async (lightId, currentColor) => {
    let nextColor;

    // Cycle through the colors: green -> orange -> black -> green
    if (currentColor === 'green') {
        nextColor = 'orange';
    } else if (currentColor === 'orange') {
        nextColor = 'black';
    } else {
        nextColor = 'green';
    }

    // Update local state
    setLightsState((prevState) =>
        prevState.map((light) =>
            light.id === lightId ? { ...light, color: nextColor } : light
        )
    );

    // Send request to update the real light
    try {
        const body = JSON.stringify({
            on: nextColor !== 'black',
            bri: nextColor === 'green' ? 254 : nextColor === 'orange' ? 51 : 0,
        });

        await fetch(`${baseUrl}/lights/${lightId}/state`, {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body,
        });

        console.log(`Light ${lightId} color changed to ${nextColor}`);
    } catch (error) {
        console.error(`Error updating light ${lightId} state:`, error);
    }
};

  return (
    <div className="App">
      <Container className="app-container">
        <Row className="justify-content-center">
          <Col xs={12} md={8} lg={6}>
            <header className="app-header">
              <h1>
                <span className="glitch-text">Welcome to Modbox</span>
              </h1>
            </header>

            <div className="button-container">
              <div className="d-grid gap-2">
                <Button variant="outline-light" size="lg" onClick={handleShow}>
                  YouTube Downloader
                </Button>
                <Button variant="outline-light" size="lg" onClick={handleLightsShow}>
                  Lights Controller
                </Button>
              </div>
            </div>
            <div className="lyles">
            “Out of the strong, came forth sweetness.” It says that on the tins of Lyle’s Golden Syrup. Have you ever noticed that? It has a picture of a lion, dead, surrounded by bees, buzzing around, and feasting on the lion’s innards. It is from a Biblical story, someone was going somewhere, saw the lion dead in the sunshine, and carried on. On his way back, the bees had started to form a hive within the lion, and were creating honey. Hence out of the strong…
            </div>
            <MP3Player />
            {responseMessage && (
              <Alert variant={responseMessage.includes('successfully') ? 'success' : 'danger'}>
                {responseMessage}
              </Alert>
            )}
          </Col>
        </Row>
      </Container>

      <Modal show={showModal} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Enter YouTube URL and Email</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Please note all YouTube downloads are limited (and compressed) to 20MB. Ensure the video is shorter than a
            minute for best results.
          </p>
          <Form>
            <Form.Group controlId="urlInput" className="mb-4">
              <Form.Label>URL</Form.Label>
              <Form.Control
                type="url"
                placeholder="Enter YouTube video URL"
                value={url}
                onChange={handleUrlChange}
              />
            </Form.Group>
            <Form.Group controlId="emailInput">
              <Form.Label>Destination Email</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter recipient's email"
                value={destinationEmail}
                onChange={handleEmailChange}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSubmit} disabled={loading}>
            {loading ? 'Downloading video and sending it to your email...' : 'Submit'}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showLightsModal} onHide={handleLightsClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Lights Controller</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="lights-container">
            <div className="lights-section">
              <h4>Sage's Room</h4>
              <div className="light-box">
                {lightsState.map((light) => (
                  <div
                    key={light.id}
                    className={`light-circle ${light.color}`}
                    onClick={() => toggleLightColor(light.id, light.color)} // Add click handler
                  ></div>
                ))}
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleLightsClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default App;
